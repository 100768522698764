$BUCKET-URL: "https://storage.googleapis.com/live-connect/teleport";
.container {
    position: relative;
    height: 100vh;
  }

  
.box {
      background-color: #6285ff;
       text-align: center;
       height: 48px;
       display: flex;
       justify-content: center;
       align-items: center;
       position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      width: 100%;
   }


.box-content{
  color: white;
      font-weight: 500;
  }
    
  
    //   svg {
    //     width: 50px;
    //     height: 25px;
