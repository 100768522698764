.owner-info {
    position: absolute;
    z-index: 201;
    left: 24px;
    top: calc(50% + 60px);
    height: 500px;
    transform: translate(0, -50%);
    width: 228px;
    color: $white;
    border-radius: 8px;
    // We commented due to flicking issue
    // -webkit-backdrop-filter: blur(40px);
    // backdrop-filter: blur(40px);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.07);
    text-align: center;
    background-color: rgba($color: #000000, $alpha: 0.5);

    .profile-pic {
        height: 180px;
        width: 180px;
        border-radius: 8px;
        position: absolute;
        background-color: rgba($color: #000000, $alpha: 0.5);
        left: 50%;
        transform: translate(-50%, 0);
        top: -50px;

        img {
            height: 100%;
            width: 100%;
            border-radius: 8px;
            position: relative;
            z-index: 1;
            object-fit: cover;
        }

        figcaption {
            font-size: 50px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

        }

        &.user-initial {
            background-color: $cornflowerBlue;
        }


    }

    .owner-name {
        margin-top: 180px;
        font-size: 20px;
        font-weight: 500;

    }

    .designation,
    .description {
        font-weight: 400;
        font-size: 15px;
        color: $txt-color-tertiary;
    }

    .designation {
        margin-bottom: 75px;
        display: block;
    }

    .description {
        padding: 0 30px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical; 
        overflow: hidden;
    }


    @include mq("desktop-medium", max) {
        @include transform(translate(0%, -50%) scale(0.9));
    }

    @include mq("desktop", max) {
        @include transform(translate(0%, -50%) scale(0.8));
    }

    @include mq("tablet-wide", max) {
        @include transform(translate(0%, -50%) scale(0.7));
    }

    @media only screen and (max-height: 750px) {
        @include transform(translate(0%, -50%) scale(0.9));
    }

    @media only screen and (max-height: 700px) {
        @include transform(translate(0%, -50%) scale(0.8));
    }


    @media only screen and (max-height: 600px) {
        @include transform(translate(0%, -50%) scale(0.7));
    }


}