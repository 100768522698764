.videocall-active {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100vw;
  height: 100vh;


  .joined-peers {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 20;
    padding: 10px;
    transition: transform 0.2s cubic-bezier(0.42, 0.0, 1.0, 1.0);

   

    .peers {
      border-radius: 10px;
      position: relative;
      background-color: rgba(0, 0, 0, 0.5);



      .active-indication {
        position: absolute;
        height: calc(100% + 5px);
        width: calc(100% + 5px);
        top: 50%;
        left: 50%;
        z-index: -1;
        transform: translate(-50%, -50%);
        border: solid 2px $cornflowerBlue;
        border-radius: 12px;
        visibility: hidden;

        &.show {
          visibility: visible;
        }
      }


      video {
        height: 100%;
        width: 100%;
        border-radius: 7px;
        object-fit: cover;
        position: relative;
        z-index: 2;
        transform: rotateY(180deg);

        &.no-cover {
          object-fit: contain;
        }
      }

      button {
        position: absolute;
        top: 5px;
        z-index: 4;
        visibility: hidden;

        &.disabled{
          cursor: not-allowed;
         
        }

        &.btn-mute {
          right: 5px;

          &.active {
            background-color: $blood-red;
            visibility: visible;
           
          }
        }

        &.btn-pinned {
          left: 5px;

          &.active {
            background-color: $cornflowerBlue;
            visibility: visible;
          }

        }


        svg {
          height: 10px;
          width: auto;

        }
      }
   

      .peer-name {
        position: absolute;
        bottom: 5px;
        left: 50%;
        transform: translate(-50%);
        font-size: 14px;
        color: $white;
        z-index: 4;
        font-weight: 600;
        white-space: nowrap;
        max-width: 80%;
        text-overflow: ellipsis;
        overflow: hidden;
        visibility: visible;
        transition:all 0.2s ease-in-out;
        opacity: 0;
        
      }

      .hover-overlay {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        border-radius: 8px;
        // background-color: rgba(0, 0, 0, 0.5);
        // opacity: 1;
        // visibility: hidden;
        z-index: 3;
        opacity: 0;
        pointer-events: none;
        transition: all 0.2s ease-in-out;
        background-image: linear-gradient(180deg, transparent 80%, #000000 125%);
        &.show {
          visibility: visible;
          opacity: 1;
        }

        &.blur {
          filter: blur(8px);
          opacity: 1;
          visibility: visible;

        }
      }

      &:hover {
        .hover-overlay {
      
          opacity: 1;
        }

        button {
          visibility: visible;
        }

        .peer-name {
 
          opacity: 1;
        }

      }

    }

   


    &.speaker-view {
   
        width: 220px;
        max-height: 75vh;
        overflow: auto;
        // padding: 10px;
      

 
      &::-webkit-scrollbar {
        width: 0px;
        /* Remove scrollbar space */
        background: transparent;
        /* Optional: just make scrollbar invisible */
      }


      

      @media screen and (max-height: 600px) {
        max-height: 50vh;
      }


      .peers {
        width: 180px;
        height: 100px;
        margin: 0 auto 15px auto;

        button {
          height: 22px;
          width: 22px;
        }

      }


    }

    &.grid-view {
        width: 100%;
        max-height: calc(100vh - 150px);
        text-align: center;
        padding: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: none;
 
 
      .peers {

        display: inline-block;
        margin: 4px;
        z-index: 0;
        // transition: width 0.3s ease-out, height 0.3s ease-out;

        .peer-details {
          height: 100%;
          width: 100%;
          max-height: 60px;
          max-width: 60px;
          z-index: -1
        }


      }
    }
  }

  //Styles for videocamera on/off status
  .main-participant {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100vw;
    height: 100vh;
    max-width: 100vw;
    transition: max-width 0.2s ease-out;


    
    // &.lq-video {
    //   // top: 50%;
    //   // left: 50%;
    //   // transform: translate(-50%, -50%);

    //   .active-peer {
    //     object-fit: contain;
    //   }
    // }

    .active-peer {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: relative;
      z-index: 2;
      transform: rotateY(180deg);

      @include mq(phone, max) {
       object-fit: contain;
       background-color: $black;
    
      }
    }

    .circular-preview {
      z-index: 1;

      .participant-info {
        height: 100%;
        width: 100%;
        position: relative;

        .peer-details {
          height: 160px;
          width: 160px;
          z-index: 2;

          &::after {
            font-size: 48px;
            font-weight: 600;

          }

        }

        .peer-name {
          position: absolute;
          font-size: 24px;
          font-weight: 600;
          color: $white;
          left: 50%;
          transform: translate(-50%, 0);
          bottom: 100px;
          white-space: nowrap;
          max-width: 65%;
          text-overflow: ellipsis;
          overflow: hidden;
        }

      }

      .screen-share-notification {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;

        i {
          display: flex;
          margin: auto;
          justify-content: center;
          align-items: center;
          height: 110px;
          width: 110px;
          border-radius: 50%;
          background-color: $cornflowerBlue;
          position: relative;

          svg {
            width: 39px;
            height: auto;
          }

        }

        p {
          color: $white;
          font-size: 24px;
          margin-top: 30px;
          font-weight: 600;
          white-space: nowrap;

        }

        button {
          margin-top: 40px;
          border-color: transparent;
        }
      }
    }

    &.off {

      video {
        visibility: hidden;

      }

      .circular-preview {
        visibility: visible;
      }

    }
    
    &.chat-open{
      max-width: calc(100vw - 375px);

      .circular-preview{
        // @include transform(translate(-50%, -50%) scale(0.7));
      }
      .active-peer{
        object-fit: contain;
        background-color: black;
      }
    }
  }

  &-gradient {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 50%;
    @include bg-gradient-t2b(rgba($black, 0), $black);
    opacity: 0.5;
    pointer-events: none;
  }




}


.videocall-controls {
  position: absolute;
  bottom: 16px;
  left: 50%;
  z-index: 10;
  font-size: 0;
  white-space: nowrap;
  @include transform(translateX(-50%));
  transition: 0.2s cubic-bezier(0.42, 0.0, 1.0, 1.0);


    @include mq(phone, max) {
      transform:translate(-50%,15px)scale(0.7);
  
    }

  [class*="btn-ctrl"] {
    width: 55px;
    height: 55px;
    margin-right: 16px;
    &:last-of-type {
      margin-right: 0;
    }
  }

  .focus-btn {
    &.pressed {
      transform: scale(.7);
    }
    svg {
      height: 32px;
      width: 32px;
    }
  }

}