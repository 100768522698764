.login-popup{
  background-color: #ffffff;
  position: fixed;
  width: 530px;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  border-radius:4px;
  box-shadow: 0 1px 4px 0 rgba(49, 54, 62, 0.14);
  z-index:3;
  padding:40px 60px;
  display:none;

  h2{
      margin:0px 0px 30px;
      font-size:24px;
      color: #38404c;
      text-align:center;
      opacity:0.9;
      font-weight:normal;
  }

  code{
      &.btn-close{
          font-size: 37px;
          line-height: 0px;
          position: absolute;
          right: 15px;
          top: 25px;
          opacity: 0.3;
          cursor:pointer;
      }
  }

  a{
      width: 200px;
      height: 40px;
      display: inline-block;
      font-size: 14px;
      font-weight: 500;
      line-height: 2.14;
      padding: 5px 20px 5px 46px;
      border-radius:3px;
      box-shadow: 0 1px 2px 0 rgba(57, 65, 77, 0.12);

      &.link-google{
          border: 1px solid #e0e7f1;
          color: #39414d;
          background:url("#{$BUCKET-URL}/assets/0.1/images/conference/icon-google-plus-colored.svg") 12px no-repeat;
      }

      &.link-facebook{
          border: 1px solid #3b5998;
          color: #ffffff;
          margin:0px 0px 0px 7px;
          background: #3b5998 url("#{$BUCKET-URL}/assets/0.1/images/conference/icon-facebook-white.svg") 12px no-repeat;
      }

  }

  cite{
      display: block;
      border: 1px solid #dfe6f0;
      margin: 30px 0px;
      border-width: 0px 0px 1px;

      &:before{
          content: "OR";
          font-style: normal;
          margin: -17px 0px 0px 187px;
          font-weight: bold;
          color: #39414d;
          font-size: 14px;
          display: block;
          position: absolute;
          background-color: #ffffff;
          padding: 10px;
      }
  }

  .form-input{
      margin:0px 0px 16px;
      label{
          font-size:15px;
          color:#39414d;
      }

      input{
          display: block;
          width: 100%;
          font-size: 14px;
          height: 44px;
          padding: 0px 15px;
          border-radius: 3px;
          background-color: #ffffff;
          box-shadow: inset 0 0 7px 0 rgba(57, 65, 77, 0.16);
          border: solid 1px #e0e7f1;
          margin:4px 0px 0px;
      }

      a {
          float: right;
          font-size: 14px;
          font-weight: 500;
          color: #6285ff;
          line-height: 1.43;
          cursor: pointer;
          width: auto;
          height: auto;
          padding: 0px;
          box-shadow: none;
          border-radius: 0px;
      }
  }

  .form-btn{
      text-align:center;
      margin:30px 0px 0px;
      display:block;
      button{
          cursor: pointer;
          font-size:14px;
          padding:12px 16px;
          color:#ffffff;
          border-radius: 3px;
          background-color: #6285ff;
          box-shadow: 0 1px 2px 0 rgba(57, 65, 77, 0.12);
          border:0px;
          width:250px;
          outline:0;
          font-weight:500;
      }
      p{
          font-size: 16px;
          color: #39414d;
          margin:16px 0px 0px;

          a {
              font-weight: 500;
              color: #6285ff;
              width: auto;
              height: auto;
              padding: 0px;
              box-shadow: none;
              border-radius: 0px;
          }
      }
  }
}
.sierra-popup{
display: block;
position: fixed;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
padding: 12px 22px;
width: 514px;
#loginForm{
  p{
    padding-right: 25px !important;
  }
  .form-btn{
    button{
      border-radius: 50px;
      padding: 10px 15px;
      margin-bottom: 10px;
      font-size: 15px;
      width: 150px;
      &.faded{
        pointer-events: none;
        opacity: 0.5;
      }
    }
    margin-top: 22px;
  }
}
h2{
  margin: 0 0 20px;
  font-size: 17px;
  font-weight: 500;
  color: #39414d;
  text-align: center;
}
.screen-switches{
  text-align: center;
  button{
    width: 160px;
    padding: 5px;
    background: linear-gradient(0deg,#ebeff4,#fff);
    border-radius: 4px;
    cursor: pointer;
    font-size: 15px;
    outline: none;
    border-style: solid;
    border-width: 1px;
    border-color: #e8e8e8;
    color: #39414d;
    &:first-of-type{
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    &:last-of-type{
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    &.selected{
      background: #6285ff;
      color: #fff;
      border-color: #6285ff;
      cursor: default;
    }
  }
}
.screens-thumb{
  max-height: 230px;
  min-height: 120px;
  overflow-y: auto;
  margin-top: 20px;
  &.inset-shadow{
    height: 230px;
    box-shadow: inset 0 0 7px 0 rgba(57,65,77,.16);
    padding: 5px;
    ul{
      height: 220px;
      overflow-y: auto;
    }
  }
  ul{
    list-style: none;
    margin-top: 0;
    li{
      padding: 10px 5px;
      border-radius: 4px;
      display: inline-block;
      vertical-align: top;
      width: 146px;
      margin-right: 10px;
      margin-bottom: 10px;
      cursor: pointer;
      img{
        width: 140px;
        height: 140px;
        object-fit: contain;
      }
      &:nth-child(3n){
        margin-right: 0;
      }
      &:hover{
        background-color: #ebeff4;
      }
      &.selected{
        background-color: #ebeff4;
      }
      p{
        font-size: 14px;
        color: #39414d;
        font-weight: 500;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        text-align: center;
      }
    }
  }
  .fullscreen-thumb{
    p{
      font-size: 15px;
    }
  }
}
}
